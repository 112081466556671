import React, { ReactElement } from 'react';
import { VisuallyHidden } from 'reakit/VisuallyHidden';
import Table from '@csv/styleguide/src/elements/table/table';
import { Sportlink_RankingTableRow } from '../generated/graphql-types';

interface RankingTableProps {
  rankingRows: [Sportlink_RankingTableRow];
}

export default function RankingTable({
  rankingRows,
}: RankingTableProps): ReactElement {
  return (
    <Table minWidth={'100%'}>
      <thead>
        <tr>
          <th>
            <VisuallyHidden>Positie</VisuallyHidden>
          </th>
          <th>Team</th>
          <th style={{ textAlign: 'center' }}>
            <span aria-hidden="true">G</span>
            <VisuallyHidden>Gespeeld</VisuallyHidden>
          </th>
          <th style={{ textAlign: 'center' }}>
            <span aria-hidden="true">W</span>
            <VisuallyHidden>Winst</VisuallyHidden>
          </th>
          <th style={{ textAlign: 'center' }}>
            <span aria-hidden="true">G</span>
            <VisuallyHidden>Gelijk</VisuallyHidden>
          </th>
          <th style={{ textAlign: 'center' }}>
            <span aria-hidden="true">V</span>
            <VisuallyHidden>Verlies</VisuallyHidden>
          </th>
          <th style={{ textAlign: 'center' }}>
            <span aria-hidden="true">P</span>
            <VisuallyHidden>Punten</VisuallyHidden>
          </th>
          <th style={{ textAlign: 'center' }}>
            <span aria-hidden="true">DS</span>
            <VisuallyHidden>Doelsaldo</VisuallyHidden>
          </th>
          <th style={{ textAlign: 'center' }}>
            <span aria-hidden="true">+</span>
            <VisuallyHidden>Doelpunten voor</VisuallyHidden>
          </th>
          <th style={{ textAlign: 'center' }}>
            <span aria-hidden="true">-</span>
            <VisuallyHidden>Doelpunten tegen</VisuallyHidden>
          </th>
        </tr>
      </thead>
      <tbody>
        {rankingRows.map((row: Sportlink_RankingTableRow, i: number) => (
          <tr key={i}>
            <td>{row.position}</td>
            <td>{row.teamName}</td>
            <td style={{ textAlign: 'center' }}>{row.played}</td>
            <td style={{ textAlign: 'center' }}>{row.won}</td>
            <td style={{ textAlign: 'center' }}>{row.drawn}</td>
            <td style={{ textAlign: 'center' }}>{row.lost}</td>
            <td style={{ textAlign: 'center' }}>
              <strong>{row.points}</strong>
            </td>
            <td style={{ textAlign: 'center' }}>{row.goalDifference}</td>
            <td style={{ textAlign: 'center' }}>{row.goalsFor}</td>
            <td style={{ textAlign: 'center' }}>{row.goalsAgainst}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
