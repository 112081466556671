import React, { ReactElement } from 'react';
import { compareAsc, parse, format } from 'date-fns';
import nlLocale from 'date-fns/locale/nl';

import {
  SectionList,
  SectionListItem,
  StickyUnorderedList,
  StickyListItem,
} from '@csv/styleguide/src/features/sticky-list/sticky-list';
import Heading from '@csv/styleguide/src/elements/heading/heading';
import MatchCard from '@csv/styleguide/src/features/match-card/match-card';
import { Fixture } from '../apollo/generated/graphql-types';

interface FixturesTableProps {
  matches: Fixture[];
}

export interface DatedMatches<TMatches> {
  date: string;
  matches: TMatches[];
}

// @TODO have api return both day and time
// @TODO move helpers
export const groupMatchesByDate = (
  matches: Fixture[],
): DatedMatches<Fixture>[] => {
  const newMatches = [];

  for (let i = 0; i < matches.length; i++) {
    const currentMatch = matches[i];
    const { datetime } = currentMatch;
    if (datetime) {
      const groupIndex = newMatches.findIndex(
        newMatch => newMatch.date === datetime.split('T')?.[0],
      );

      groupIndex > -1
        ? newMatches[groupIndex].matches.push(currentMatch)
        : newMatches.push({
            date: datetime.split('T')?.[0],
            matches: [currentMatch],
          });
    }
  }

  return newMatches;
};

export const sortGroupMatchesASC = (
  groupedMatches: DatedMatches<Fixture>[],
) => {
  const formatString = 'yyyy-MM-dd';
  return groupedMatches.sort((a, b) => {
    const dateA = parse(a.date, formatString, new Date());
    const dateB = parse(b.date, formatString, new Date());
    return compareAsc(dateA, dateB);
  });
};

export default function FixturesTable({
  matches,
}: FixturesTableProps): ReactElement {
  const groupedMatches = groupMatchesByDate(matches);
  const sortedGroupedMatches = sortGroupMatchesASC(groupedMatches);

  return (
    <div>
      {sortedGroupedMatches.length > 0 &&
        sortedGroupedMatches.map(group => (
          <SectionList key={group.date}>
            <SectionListItem>
              <Heading as={'h3'} variant={'h3'}>
                {format(new Date(group.date), 'iiii d MMMM', {
                  locale: nlLocale,
                })}
              </Heading>
              <StickyUnorderedList>
                {group.matches.length > 0 &&
                  group.matches.map(match => (
                    <StickyListItem key={match.matchId}>
                      <MatchCard
                        homeTeam={match.homeTeam}
                        awayTeam={match.awayTeam}
                        timeOrScore={format(new Date(match.datetime), 'HH:mm', {
                          locale: nlLocale,
                        })}
                        location={match.accommodation}
                        matchId={match.matchId}
                        status={match.status}
                      />
                    </StickyListItem>
                  ))}
              </StickyUnorderedList>
            </SectionListItem>
          </SectionList>
        ))}
    </div>
  );
}
