import { StyledSelect as Select } from '@csv/styleguide/src/features/select/select';
import React, { ReactElement } from 'react';
import { Sportlink_Group } from '../generated/graphql-types';

// export type GroupItem = {
//   teamName: string;
//   divisionGroupName: string;
//   groupId: number;
// };

interface Props {
  items: Array<
    Pick<Sportlink_Group, 'groupId' | 'teamName' | 'divisionGroupName'>
  >;
  handleClick: (value: string) => void;
  defaultValue?: string;
}

export default function GroupMenu({
  items,
  handleClick,
  defaultValue,
}: Props): ReactElement {
  const optionItems = items.map(i => ({
    value: i.groupId.toString(),
    text: `${i.teamName} - ${i.divisionGroupName}`,
  }));

  return (
    <>
      <Select
        id="test-id"
        label="Competities:"
        handleClick={handleClick}
        defaultValue={defaultValue}
        items={optionItems}
        helperText="Kies een competitie"
      />
    </>
  );
}
