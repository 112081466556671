import * as React from 'react';
import styled from 'styled-components';

const TableContainer = styled.div`
  width: 100%;
  height: auto;
  overflow-x: auto;
`;

interface BaseTableProps {
  minWidth?: string;
  bordered: boolean;
}

// @FIXME props want to be able to have sticky columns?

const BaseTable = styled.table<BaseTableProps>`
  width: ${props => (props.minWidth ? props.minWidth : 'auto')};
  border-spacing: 0;
  border-collapse: collapse;
  border-radius: 4px;
  box-shadow: none;
  table-layout: unset;
  margin-bottom: 0;

  hr {
    border: 0;
    border-bottom: 2px dashed #e5e8ed;
  }

  tr {
    &:hover {
      background-color: rgba(0, 105, 255, 0.05);
    }
    &:not(:last-of-type) {
      border-bottom: 1px solid #e5e8ed;
    }

    &:last-of-type {
      border-bottom: ${props =>
        props.bordered ? '1px solid #e5e8ed' : 'none'};
    }

    th {
      &:first-child {
        border-left: ${props =>
          props.bordered ? '1px solid #e5e8ed' : 'none'};
      }
    }
  }

  th,
  td {
    text-align: left;
    padding: 8px 4px;
    line-height: 1rem;
    border: none;
    background: none;

    @media screen and (min-width: 500px) {
      padding: 8px 16px;
      line-height: 1.5rem;
    }
  }

  th {
    color: #2041a3;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.75rem;
    font-weight: 600;
    border-top-width: 0;
    border-left-width: 0;
    border-bottom-width: 1px;
    border-right-width: ${props => (props.bordered ? '1px' : 0)};
    border-style: solid;
    border-color: #e5e8ed;
    background-color: #f9fafc;
  }

  td {
    vertical-align: middle;
    font-size: 0.75rem;
    position: relative;
    border-right-width: ${props => (props.bordered ? '1px' : 0)};
    border-right-style: solid;
    border-right-color: #e5e8ed;

    @media screen and (min-width: 500px) {
      font-size: 0.875rem;
    }
  }

  thead {
    tr td:last-of-type {
      border-right: 1px solid #e5e8ed;
    }

    tr:last-of-type td {
      border-bottom: 1px solid #e5e8ed;
    }

    &:first-of-type th {
      border-top: ${props => (props.bordered ? '1px solid #e5e8ed' : 'none')};
    }

    td {
      background-color: white;
    }
  }

  tbody {
    tr th:last-of-type {
      border-right: 1px solid #e5e8ed;
    }

    td {
      &:first-child {
        border-left: ${props =>
          props.bordered ? '1px solid #e5e8ed' : 'none'};
      }
    }
  }
`;

export interface TableProps {
  children: React.ReactNode;
  minWidth?: string;
  bordered?: boolean;
}

const Table = ({
  children,
  minWidth,
  bordered = false,
}: TableProps): React.ReactElement => {
  // @TODO implement tab index when overflowed for a11y
  return (
    <TableContainer>
      <BaseTable minWidth={minWidth} bordered={bordered}>
        {children}
      </BaseTable>
    </TableContainer>
  );
};

export default Table;
