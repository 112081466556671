import styled from 'styled-components';
import { FaMapMarkerAlt } from 'react-icons/fa';
import React, { ReactElement } from 'react';
import { Chip } from '../../elements/chip/chip';

const BaseMatchCard = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 6px;
  padding: 12px 6px;
  justify-items: center;
  align-items: center;
  min-height: 100px;
  text-align: center;
`;

const MatchCardTime = styled.p`
  font-size: 1.5rem;
  margin: 0.5em;
  line-height: 2rem;
  font-weight: 700;

  @media screen and (min-width: 500px) {
    font-size: 2rem;
    margin: 1.5rem 1rem;
  }
`;

const MatchCardTeam = styled.p`
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
  font-weight: 400;
  word-break: break-word;

  @media screen and (min-width: 500px) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

const MatchCardLocation = styled.p`
  grid-column: span 3;
  margin: 0 0 0.75em 0;
  justify-self: stretch;
  color: rgb(85, 85, 85);
  position: relative;

  svg {
    margin-right: 6px;
  }

  span,
  svg {
    font-size: 0.875rem;
    display: inline-block;
    vertical-align: middle;
    line-height: 1.5rem;
  }
`;

const MatchCardCancelled = styled(Chip).attrs({
  kind: 'warning',
  outlined: false,
})`
  font-weight: 700;
  text-transform: uppercase;

  margin: 0.5em;

  @media screen and (min-width: 500px) {
    margin: 1.5rem 1rem;
  }
`;

interface MatchCardProps {
  homeTeam: string;
  awayTeam: string;
  location: string;
  matchId: number;
  timeOrScore: string;
  status: 'FIXTURE' | 'FINISHED' | 'CANCELLED' | 'SUSPENDED';
}

export default function MatchCard({
  homeTeam,
  timeOrScore,
  awayTeam,
  location,
  status,
}: MatchCardProps): ReactElement {
  const renderStatus = () => {
    switch (status) {
      case 'CANCELLED':
        return <MatchCardCancelled>Afgelast</MatchCardCancelled>;
      case 'SUSPENDED':
        return <MatchCardCancelled>Gestaakt</MatchCardCancelled>;
      default:
        return <MatchCardTime>{timeOrScore}</MatchCardTime>;
    }
  };
  return (
    <BaseMatchCard>
      <MatchCardTeam>{homeTeam}</MatchCardTeam>
      {renderStatus()}
      <MatchCardTeam>{awayTeam}</MatchCardTeam>
      <MatchCardLocation>
        <FaMapMarkerAlt />
        <span>{location}</span>
      </MatchCardLocation>
    </BaseMatchCard>
  );
}
