import styled from 'styled-components';
import { Tab, TabList } from '@csv/styleguide/src/features/tab/tab';
import { BaseContainerStyles } from '@csv/styleguide/src/elements/container/container';

export const TeamToolbarContent = styled.div`
  ${BaseContainerStyles};

  @media screen and (min-width: 520px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const TeamToolbar = styled.div`
  overflow: hidden;
  background: #2041a3;
  color: white;

  .MuiNativeSelect-root,
  .MuiFormHelperText-root,
  .MuiInputLabel-root,
  svg {
    color: white;
  }

  .MuiInputLabel-root {
    color: rgba(255, 255, 255, 0.82);
    &.Mui-focused {
      color: white;
    }
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid rgba(255, 255, 255, 0.42);
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgba(255, 255, 255, 0.42);
  }

  ${TabList}, ${Tab} {
    background: transparent;
    color: white;
  }

  ${TabList} {
    margin-bottom: 0;
    border-bottom: 1px solid white;
  }

  ${Tab} {
    &::after {
      height: 5px;
      background-color: transparent;
    }

    &[aria-selected='true'],
    &:hover {
      color: white;
      &::after {
        background-color: #90c8fb;
      }
    }
  }
`;

export default TeamToolbar;
