import Heading from '@csv/styleguide/src/elements/heading/heading';
import Table from '@csv/styleguide/src/elements/table/table';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Sportlink_TeamPlayer } from '../generated/graphql-types';

// @TODO check if further customization is needed
const TeamPlayersContentWrapper = styled.div``;

const TeamTableCaption = styled.caption`
  padding: 24px 0 12px;
  border-bottom: 1px solid #e5e8ed;
`;

interface TeamTableProps {
  teamPlayers: Sportlink_TeamPlayer[];
}

export default function TeamTable({
  teamPlayers,
}: TeamTableProps): ReactElement {
  const players = teamPlayers.filter(
    teamPlayer => !teamPlayer.role.includes('staf'),
  );
  const staff = teamPlayers.filter(teamPlayer =>
    teamPlayer.role.includes('staf'),
  );

  return (
    <TeamPlayersContentWrapper>
      <Table minWidth={'100%'}>
        <TeamTableCaption>
          <Heading as="h3" variant="h4" hasMargin={false}>
            Spelers
          </Heading>
        </TeamTableCaption>
        <thead>
          <tr>
            <th>Naam</th>
            <th>Rol</th>
          </tr>
        </thead>
        <tbody>
          {players.map((player: Sportlink_TeamPlayer, i: number) => (
            <tr key={i}>
              <td>{player.name}</td>
              <td>{player.role}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Table minWidth={'100%'}>
        <TeamTableCaption>
          <Heading as="h3" variant="h4" hasMargin={false}>
            Staf
          </Heading>
        </TeamTableCaption>
        <thead>
          <tr>
            <th>Naam</th>
            <th>Rol</th>
            <th>Functie</th>
          </tr>
        </thead>
        <tbody>
          {staff.map((player: Sportlink_TeamPlayer, i: number) => (
            <tr key={i}>
              <td>{player.name}</td>
              <td>{player.role}</td>
              <td>{player.function}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </TeamPlayersContentWrapper>
  );
}
