import * as React from 'react';
import styled from 'styled-components';
import { COLOR_BLUE_LIGHT } from './../../theme/colors/colors';

import {
  useTabState,
  Tab as BaseTab,
  TabList as BaseTabList,
  TabPanel as BaseTabPanel,
  TabStateReturn,
  TabInitialState,
} from 'reakit/Tab';

const TabsContext = React.createContext<TabStateReturn>({} as any);

export type TabsProps = {
  children: React.ReactNode;
} & TabInitialState;

export function Tabs({ children, ...initialState }: TabsProps) {
  const tab: TabStateReturn = useTabState(initialState);
  const value = React.useMemo(() => tab, Object.values(tab));

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
}

export type UnstyledTabProps = {
  disabled?: boolean;
  focusable?: boolean;
  stopId: string;
  id?: string;
  children: React.ReactNode;
};

function UnstyledTab(props: UnstyledTabProps) {
  const tab: TabStateReturn = React.useContext(TabsContext);
  return <BaseTab {...tab} {...props} />;
}

export const Tab = styled(UnstyledTab)`
  cursor: default;
  border: none;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 4px 4px 0 0;

  &::after {
    left: 0;
    right: 0;
    width: 100%;
    bottom: -1px;
    height: 2px;
    content: '';
    position: absolute;
    transition: all 0.2s ease;
    background-color: #0000;
  }

  &[aria-selected='true'],
  &:hover {
    color: ${COLOR_BLUE_LIGHT['800']};
    &::after {
      background-color: ${COLOR_BLUE_LIGHT['800']};
    }
  }

  &:focus {
    box-shadow: inset 0px 0px 0px 3px ${COLOR_BLUE_LIGHT['200']};
    outline: 0px;
  }
`;

export type UnstyledTabListProps = {
  id?: string;
  children: React.ReactNode;
};

function UnstyledTabList(props: UnstyledTabListProps) {
  const tab = React.useContext(TabsContext);
  return <BaseTabList {...tab} {...props} />;
}

export const TabList = styled(UnstyledTabList)`
  width: 100%;
  min-height: 48px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  user-select: none;

  display: flex;
  flex-direction: row;
  /* 
  position: sticky;
  top: 0;
  z-index: 1; */
  /* @TODO add sticky variant */

  border-bottom: 1px solid #0000001a;
  margin-bottom: 12px;
  background-color: #fff;
`;

export type UnstyledTabPanelProps = {
  stopId: string;
  id?: string;
  children: React.ReactNode;
};

function UnstyledTabPanel(props: UnstyledTabPanelProps) {
  const tab = React.useContext(TabsContext);
  return <BaseTabPanel {...tab} {...props} />;
}

export const TabPanel = styled(UnstyledTabPanel)`
  width: 100%;
  height: auto;
  /* overflow-x: auto; */
  /* NOTE: setting overflow to anything else cause the sticky lists not to work. */
`;
