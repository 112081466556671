import styled from 'styled-components';
import Heading from '../../elements/heading/heading';

import React, { ReactElement } from 'react';

export const SectionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  background: #f9fafc;
`;

export const SectionListItem = styled.li`
  > ${Heading} {
    text-align: center;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 12px;
    margin-bottom: 0;
    position: sticky;
    top: -1px;
    z-index: 1;
    background: #f9fafc;
    color: #2041a3;
  }
`;

export const StickyUnorderedList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 12px;
  display: flex;
  flex-direction: column;
`;

export const StickyListItem = styled.li`
  background: white;
  border-bottom: rgba(85, 85, 85, 0.1) 1px solid;

  &:hover {
    background: rgb(85, 85, 85, 0.025);
  }

  &:focus-within {
    background: rgb(85, 85, 85, 0.05);
  }
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StickyListProps {}

// eslint-disable-next-line no-empty-pattern
export default function StickyList({}: StickyListProps): ReactElement {
  return <div></div>;
}
