import { useQuery } from '@apollo/react-hooks';
import Container from '@csv/styleguide/src/elements/container/container';
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from '@csv/styleguide/src/features/tab/tab';
import { graphql } from 'gatsby';
import * as React from 'react';
import { useState } from 'react';
import FixturesTable from '../components/fixtures-table';
import Layout from '../components/layout';
import RankingTable from '../components/ranking-table';
import SEO from '../components/seo';
import TeamControls from '../components/team-controls';
import TeamTable from '../components/team-table';
import TeamToolbar, { TeamToolbarContent } from '../components/team-toolbar';
import { TeamTemplateQueryQuery } from '../generated/graphql-types';
import GROUP_DETAILS_QUERY from './../apollo/queries/groupDetailQuery';
import { GatsbyPageQueryResult } from '../types/gatsby-query';
import { PageHeaderComposition } from '@csv/styleguide/src/features/page-header/page-header';
import ResultsTable from '../components/results-table';

export const query = graphql`
  query TeamTemplateQuery($id: Int!) {
    sportlink {
      team: GetTeamById(input: { teamId: $id }) {
        ageCategory
        competition
        competitionType
        divisionGroupName
        divisionName
        gameType
        gender
        groupName
        teamGameDay
        teamId
        teamName
        teamType
        players {
          function
          gender
          name
          role
        }
        groups {
          groupId
          teamName
          divisionGroupName
        }
      }
    }
  }
`;

const TeamTemplate = ({
  data: { sportlink },
  errors,
}: GatsbyPageQueryResult<TeamTemplateQueryQuery>) => {
  const { team } = sportlink;
  const initialGroup = team?.groups?.[0]?.groupId;
  const [currentGroup, setGroup] = useState(initialGroup);

  const { loading, data, refetch } = useQuery(GROUP_DETAILS_QUERY, {
    variables: { id: currentGroup },
  });

  return (
    <Layout>
      {errors && (
        <SEO title="GraphQL Error" description="something went wrong" />
      )}
      {team && (
        <SEO
          title={team.teamName || 'Onbekend team'}
          description={team.teamName || ''}
        />
      )}

      {errors && <Container>{errors}</Container>}
      {team && (
        <>
          <PageHeaderComposition
            title={team.teamName}
            subTitle="Team"
            byline={`${team.gameType} - ${team.gender}`}
            disableVerticalMargin={true}
          />
          {/* <TeamHeader
            title={team.teamName}
            description={`${team.gameType} - ${team.gender}`}
          /> */}
          <Tabs selectedId="fixtures">
            <TeamToolbar>
              <TeamToolbarContent>
                {team.groups && (
                  <TeamControls
                    groups={team.groups}
                    handleSetGroup={setGroup}
                    currentGroup={currentGroup}
                    handleRefresh={refetch}
                  />
                )}
                <TabList aria-label="Competitieinformatie">
                  <Tab stopId="fixtures">Programma</Tab>
                  <Tab stopId="results">Uitslagen</Tab>
                  <Tab stopId="ranking">Stand</Tab>
                  <Tab stopId="teamPlayers">Team</Tab>
                </TabList>
              </TeamToolbarContent>
            </TeamToolbar>
            <TabPanel stopId="fixtures">
              {loading && (
                <p style={{ textAlign: 'center' }}>
                  Wedstrijddata wordt geladen ...
                </p>
              )}
              {!loading && !data?.group?.fixtures?.length && (
                <p style={{ textAlign: 'center' }}>Geen resultaten gevonden</p>
              )}
              {data && <FixturesTable matches={data.group.fixtures} />}
            </TabPanel>
            <TabPanel stopId="results">
              {loading && (
                <p style={{ textAlign: 'center' }}>
                  Uitslagen worden geladen ...
                </p>
              )}
              {!loading && !data?.group?.results?.length && (
                <p style={{ textAlign: 'center' }}>Geen resultaten gevonden</p>
              )}
              {data && <ResultsTable matches={data.group.results} />}
            </TabPanel>
            <TabPanel stopId="ranking">
              {loading && (
                <p style={{ textAlign: 'center' }}>Stand wordt geladen ...</p>
              )}
              {!loading && !data?.group?.ranking?.length && (
                <p style={{ textAlign: 'center' }}>Geen resultaten gevonden</p>
              )}
              {data && <RankingTable rankingRows={data.group.ranking} />}
            </TabPanel>
            <TabPanel stopId="teamPlayers">
              {team.players && <TeamTable teamPlayers={team.players} />}
            </TabPanel>
          </Tabs>
        </>
      )}
    </Layout>
  );
};

export default TeamTemplate;
