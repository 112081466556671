import gql from 'graphql-tag';

const GROUP_DETAILS_QUERY = gql`
  query GroupDetailsQuery($id: Int!) {
    group: GetGroupById(input: { groupId: $id }) {
      ranking {
        clubRelationCode
        drawn
        goalDifference
        goalsAgainst
        goalsFor
        isOwnTeam
        lossPoints
        lost
        played
        points
        position
        teamName
        won
      }
      fixtures {
        awayTeam
        datetime
        homeTeam
        isOwnTeam
        matchId
        status
        accommodation
      }
      results {
        awayTeam
        awayTeamId
        date
        homeTeam
        homeTeamId
        isOwnTeam
        matchId
        accommodation
        score
        status
      }
    }
  }
`;

export default GROUP_DETAILS_QUERY;
