import IconButton from '@material-ui/core/IconButton';
import React, { ReactElement } from 'react';
import { FaRedoAlt } from 'react-icons/fa';
import styled from 'styled-components';
import GroupMenu from '../components/group-menu';
import { Sportlink_Group } from '../generated/graphql-types';

const TeamControlsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 12px 12px;

  .MuiIconButton-root {
    align-self: center;
  }

  @media screen and (min-width: 520px) {
    padding: 12px 0;
  }
`;

interface Props {
  groups: Array<
    Pick<Sportlink_Group, 'groupId' | 'teamName' | 'divisionGroupName'>
  >;
  handleSetGroup: (group: number) => void;
  currentGroup?: number;
  handleRefresh: () => void;
}

export default function TeamControls({
  groups,
  handleSetGroup,
  currentGroup,
  handleRefresh,
}: Props): ReactElement {
  return (
    <TeamControlsContainer>
      <GroupMenu
        items={groups}
        handleClick={(value: string) => handleSetGroup(parseInt(value, 10))}
        defaultValue={currentGroup ? currentGroup.toString() : ''}
      />
      <IconButton aria-label="Data verversen" onClick={() => handleRefresh()}>
        <FaRedoAlt></FaRedoAlt>
      </IconButton>
    </TeamControlsContainer>
  );
}
