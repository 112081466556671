import styled from 'styled-components';
import theme from 'styled-theming';

import {
  COLOR_BLUE_LIGHT,
  COLOR_BLUE_DARK,
  COLOR_SUCCESS,
  COLOR_DANGER,
  COLOR_WARNING,
  COLOR_BLACK,
  COLOR_WHITE,
} from './../../theme/colors/colors';
//import typographySettings from '../../theme/typography';

const backgroundColor = theme.variants('mode', 'kind', {
  default: { light: COLOR_BLUE_LIGHT['50'], dark: COLOR_BLUE_DARK['50'] },
  primary: { light: COLOR_BLUE_LIGHT['900'], dark: COLOR_BLUE_DARK['900'] },
  success: { light: COLOR_SUCCESS, dark: COLOR_SUCCESS },
  warning: { light: COLOR_WARNING, dark: COLOR_WARNING },
  danger: { light: COLOR_DANGER, dark: COLOR_DANGER },
});

const foregroundColor = theme.variants('mode', 'kind', {
  default: { light: COLOR_BLACK, dark: COLOR_BLACK },
  primary: { light: COLOR_WHITE, dark: COLOR_WHITE },
  success: { light: COLOR_WHITE, dark: COLOR_WHITE },
  warning: { light: COLOR_BLACK, dark: COLOR_BLACK },
  danger: { light: COLOR_WHITE, dark: COLOR_WHITE },
});

export interface ChipProps {
  kind?: 'default' | 'primary' | 'success' | 'warning' | 'danger';
  outlined?: boolean; // @TODO revise outlined mode for a11y
}

export const Chip = styled.span<ChipProps>`
  text-align: center;
  white-space: nowrap;
  border-radius: 16px;
  padding: 4px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  /* text-transform: uppercase; */
  border-style: solid;
  border-color: ${({ outlined }) => (outlined ? backgroundColor : COLOR_WHITE)};
  border-width: ${({ outlined }) => (outlined ? '1px' : 0)};
  transition: background 0.1s ease-out 0s,
    box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  background: ${({ outlined }) => (outlined ? COLOR_WHITE : backgroundColor)};
  color: ${({ outlined }) => (outlined ? backgroundColor : foregroundColor)};
`;

Chip.defaultProps = {
  kind: 'default',
  outlined: false,
};
