import React from 'react';
import MuiNativeSelect from '@material-ui/core/NativeSelect';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import styled from 'styled-components';
import { FaAngleDown } from 'react-icons/fa';

export type SelectItem = {
  value: string;
  text: string;
};
export interface SelectProps {
  id: string;
  label: string;
  defaultValue: string;
  items: SelectItem[];
  helperText?: string;
  className?: string;
  handleClick: (value: string) => void;
}
const Select = ({
  id,
  label,
  handleClick,
  defaultValue,
  items,
  helperText,
  className,
}: SelectProps) => {
  const [state, setState] = React.useState(defaultValue);
  const handleChange = () => (event: React.ChangeEvent<{ value: string }>) => {
    setState(event.target.value);
    handleClick(event.target.value);
  };

  return (
    <div className={className}>
      <FormControl>
        <InputLabel htmlFor={`${id}-select`}>{label}</InputLabel>
        <MuiNativeSelect
          inputProps={{
            name: id,
            id: `${id}-select`,
          }}
          value={state}
          onChange={handleChange()}
          IconComponent={FaAngleDown}
        >
          {items.map((item: SelectItem) => (
            <option key={item.value} value={item.value}>
              {item.text}
            </option>
          ))}
        </MuiNativeSelect>
        {helperText && <MuiFormHelperText>{helperText}</MuiFormHelperText>}
      </FormControl>
    </div>
  );
};

export const StyledSelect = styled(Select)`
  .MuiInputLabel-root,
  .MuiNativeSelect-root,
  option,
  .MuiFormHelperText-root {
    font-family: 'Camphor';
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiFormHelperText-root {
    font-weight: 300;
    letter-spacing: 0;
  }
`;
